.Content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    img{
        height: 7vh;
        border-radius: 0.3rem;
        min-height: 60px;
    }
    .behavior_container{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
    }
    >div {
        display: flex;
        width: 100%;
        align-items: flex-start;
    
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0.2rem;
        
        div {
            display: flex;
            
            gap: 0.2rem;
            h6{
                color:#acacac;
            }
        }
    }
    h1 {
        font-size: 1.1rem;
        color: #272727;
    }
}
@media (max-width: 920px) {
    .Content{
        align-items: center;
        gap: 0rem;
        img{
            border-radius: 0.3rem;
        }
    }
    
}