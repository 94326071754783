@keyframes Show {
    0% {
        transform: translateY(-50vh);
    }

    100% {
        opacity: 1;
    }
}

.container {
    width: 100%;
    font-size: 0.75rem;
    background-color: black;
    min-height: 100vh;
    background-image: radial-gradient(rgba(255, 255, 255, 0.186) 10%, rgb(0, 0, 0) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;

    div {
        border-radius: 1rem;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: 1.68 * 20rem;
        height: 20rem;
        background-color: rgba(0, 0, 0, 0.583);
        opacity: 0;
        animation: Show 1 1s forwards;
        text-align: center;
    }
    p {
        font-weight: 300;
        padding: 0 3rem;
  
    }
    button {
        border-radius: 0.7rem;
        margin-top: 5rem;
        border: none;
        width: 150px;
        height: 45px;
        transition: all 0.4s ease;
        cursor: pointer;
        &:hover {
            font-weight: 500;
            border: 2px solid white;
            background-color: black;
            color: white;
        }
    }
}
