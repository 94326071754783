@keyframes is-rotating {
    to {
        transform: rotate(1turn);
    }
}
@keyframes fade-in {
    0% {
        transform: translateX(-50px);
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.contentColor {
    display: flex;
    flex-direction: column;
    opacity: 0;
    animation: fade-in 0.5s 1 forwards;
    gap: 1rem;
    > div {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        h3 {
            margin: 0;
            min-width: 220px;
        }
    }
    .boxColor {
        width: 1.5vw;
        height: 1.5vw;
        min-width: 25px;
        min-height: 25px;
        border-radius: 0.4rem;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
    }

    .labelProfile {
        width: 150px;
        padding: 0.4rem;
        text-align: center;
        color: white;
        text-transform: capitalize;
        border-radius: 0.4rem;
        background-color: #5fcc06;
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 65vw;
    gap: 1rem;
    margin-left: 22vw;
    margin-bottom: 6rem;
    hr {
        border: 0;
        border-top: 1px solid rgba(128, 128, 128, 0.252);
        width: 100%;
    }
    section {
        padding: 1rem 1rem;
        background-color: white;
        border: 1px solid rgba(128, 128, 128, 0.38);
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
        border-radius: 0.4rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 1rem;
        width: 100%;

        h2 {
            font-size: 1.5rem;
            color: rgb(255, 204, 0);
        }
        label {
            color: rgb(255, 204, 0);
            font-size: 1.1rem;
        }
        h3 {
            color: rgb(53, 53, 53);
            font-size: 1.2rem;
            font-weight: 800;
        }
        span {
            color: rgb(53, 53, 53);
            font-weight: 800;
            font-size: 0.8rem;
        }
        div {
        }
    }
}
.loaderMore {
    display: flex;
    background-color: "white";
    border: 1px solid rgb(194, 194, 194);
    border-radius: 0.2rem;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 35px;
    width: 200px;
    svg {
        animation: is-rotating 1s infinite;
    }
}

.table {
    width: 100%;
    thead {
        th {
            width: 20%;
            padding: 1rem 0rem;
            text-align: start;
            font-size: 0.8rem;
            font-weight: 900;
            color: var(--greyDark);
        }
        th:last-child {
            width: 40%;
        }
    }

    tbody {
        tr {
            td {
                padding: 0.5rem 0rem;
                text-align: start;
                font-size: 0.7rem;
                color: var(--greyLight);
                font-weight: 700;
            }
        }
    }
}

.headerInfo {
    width: 100%;
    padding: 1rem 1rem;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
    background-color: white;
    border: 1px solid rgba(128, 128, 128, 0.38);
    display: flex;
    justify-content: space-between;
    border-radius: 0.4rem;

    img {
        border-radius: 0.4rem;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
        border: 0.5px solid rgba(128, 128, 128, 0.2);
    }
    h3 {
        color: rgb(53, 53, 53);
        font-size: 0.9rem;
        font-weight: 800;
    }
    p {
        color: rgb(167, 167, 167);
        font-size: 0.8rem;
    }
    > div {
        display: flex;
        gap: 1rem;
        div {
            display: flex;
            flex-direction: column;
        }
        svg {
            animation: is-rotating 1s infinite;
        }
    }
}
.contentSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    h2 {
        font-weight: 900;
        svg {
            fill: rgb(255, 204, 0);
            animation: is-rotating 1s infinite;
        }
    }
}

.section3 {
    .contentSection {
        display: grid;
        grid-template-areas: "info info info";
        grid-column: 1fr 1fr 2fr;
        gap: 2rem;
        svg {
            fill: rgb(255, 204, 0);
            animation: is-rotating 1s infinite;
        }
        div {
            grid: info;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            h2 label {
            }
        }
        flex-wrap: wrap;
    }
}

.section2 {
    .contentSection {
        display: grid;
        grid-template-areas: "info info info info";
        grid-column: 1fr 1fr 1fr 1fr;
        gap: 2rem;

        svg {
            fill: rgb(255, 204, 0);
            animation: is-rotating 1s infinite;
        }
        div {
            grid: info;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            h2 label {
            }
        }
        flex-wrap: wrap;
    }
}
.title {
    font-size: 1.3rem;
    margin-bottom: 2rem;
    margin-top: 3rem;
}
.hidenSection {
   display: flex;
}

.loader {
    z-index: 1;
    position: fixed;
    left: 50%;
    right: 50%;
    top: 50%;
    transform: scale(3);
    svg {
        animation: is-rotating 1s infinite;
    }
}
.insights {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    .contentColor {
        width: 50%;

        align-items: flex-start;
        > div {
            align-items: flex-end;
        }
        h2 {
            margin-top: auto;
        }
    }
}
.insightsDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.insights2 {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    div {
        display: flex;
        gap: 1rem;
        width: 100%;
    }
}
.insights3 {
    display: flex;
    gap: 1rem;
}
.containerSearch {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.styleAccredited {
    display: flex;
    margin-right: auto;
    color: #373737;
    background-color: #e1e1e1;
    margin-bottom: 0;
    width: 100%;
    height: 125px;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    padding: 1.3rem 1.3rem;
    border: 1px solid rgba(128, 128, 128, 0.38);
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0.4rem;
    position: relative;
}
.styleAccreditedUsers {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    color: #373737;
    background-color: #fff;
    margin-bottom: 0;
    width: 100%;
    height: 125px;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1.5rem;
    border: 1px solid rgba(128, 128, 128, 0.38);
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0.4rem;
    position: relative;
}
.showMore {
    svg {
        display: none;
    }
}

.cardPersonalAdvisor {
    // max-width: 180px;
    // max-height: 120px;
    margin-bottom: 1.5rem;
}
.histogram {
}
.notFound {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 60vh;
    font-size: 1rem;
    color: gray;
}
.elasticPrice {
    h3{
        display: none;
    }
    > div {
        display: flex;
        padding: 1rem 0;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        h4 {
            margin: 0;
        }
   
        .bar {
            position: relative;
            width: 70%;
            height: 12px;
            background-color: #c5c5c5;
            border-radius: 25rem;

            span {
                bottom: -5px;
                position: absolute;
                width: 22px;
                height: 22px;
                border: 3px solid;
                border-radius: 25rem;
                border-color: #ffcb05;
                background-color: white;

                h3 {
                    display: block;
                    position: absolute;
                    top: -20px;
                    left: -100%;
                }
            }
        }
    }
}

.BehaviorUser {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.contentResponsive {
    display: none;
}
.labels {
    display: flex;
    gap: 0.5rem;
    width: 100%;
}

.title_responsive {
    display: none;
    font-size: 0.9rem;
    font-weight: 900;
    color: #575757;
    margin-right: auto;
    margin-left: 1rem;
}
.indetificationResposive {
    display: none;
    margin-bottom: 1rem;
}

.legendGraphic{
    >div{
        display: flex;
        align-items: space-between;
        justify-content: center;
        gap: 0.5rem;
    }
    p{
        font-size: 0.6rem;
        color: #575757;
    }
    h6{
        margin-left: auto;
    }
    position: absolute;
    top: 4.3rem;
    right: 50px;
}

.matchingSectionResponsive{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.container_matching_card{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    position: relative;
    .contentAdd{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #dddddd8e;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        position: absolute;
        z-index: 1;
    }
}
.matchingSection{
   
}
.labelImage{
    margin-right: 0.5rem;
    font-size: 0.7rem;
    font-weight: 900;
    background-color: #e9bd0e;
    color: white;
    border-radius: 0.2rem;
    padding: 0.3rem 0.5rem;
}
.answeredForms{
    display: flex;
    overflow-x: hidden;
}
.titleForm{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.3rem;
    height: 40%;
    font-weight: 700;
    font-size: 1rem;
    color: #474747;

    .questioIdDescrition{
        font-weight: 900;
        font-size: 1.2rem;
        color: #474747;
    }
    >p{
        font-size: 0.75rem;
        color: #37373783;
    }
}
.container_answered{
    display: flex;
    width: 90%;
    margin: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    align-items: center;
    justify-content: flex-start;
    gap: 7rem;
    transition: all ease 0.3s;
    flex-direction: row;
}
.container_answered::-webkit-scrollbar {
    display: none;
}
.card_form{
    display: flex;
    transition: all ease 0.3s;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    border: 0.5px solid #47474771;
    height: 390px;
    width: 23%;
    min-width: 220px;
    padding: 1rem;
    border-radius: 0.5rem;
    position: relative;
    ul{
        display: flex;
        flex-direction: column; 
        justify-content:flex-start;
        gap: 0.5rem; 
        height: 75%;
    }
    li{
        font-weight: 700;
        font-size: 0.8rem;
        padding-left: 15px;
        color: #bdbdbd;
        position: relative;
    }
    li::before{
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        left: 0;
        bottom: 3px;
        border-radius: 50rem;
        background-color: #bdbdbd;
    }
    .optionSelectedForm{
        color:#5fcc06;
        &::before{
            background-color: #5fcc06;
        }
    }
}
.rightArrow{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 1rem;
    z-index: 1;
    width: 30px;
    height: 30px;
    cursor: pointer;
    img{
        color: #6b6b6b;
        width: 100%;
        height: 100%;
    }
}
.leftArrow{
    position: absolute;
    z-index: 1;
    left:1rem;
    width: 30px;
    height: 30px;
    cursor: pointer;
    img{
        color: #6b6b6b;
        width: 100%;
        height: 100%;
    }
}
.container_quiz{
    margin: 0; 
}
.card_quiz{
    display: flex;
    height: 160px;
    gap: 2rem;
    img{
        border-radius: 0.5rem;
        width: 110px;
        height: 150px;
        object-fit: cover;
    }
    >div{
        display: flex;
        height: 100%;
      
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0.5rem;
        >div{
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: white;
            
            gap: 0.5rem;
          
            p{
                font-size: 0.8rem;
                font-weight: 900;
                color: white;
                text-align: center;
                background-color: #5fcc06;
                border-radius: 0.3rem;
                padding: 0.3rem 0.8rem;
                width: 100%;
            }
            label{
                background-color: #5fcc06;
                border-radius: 0.3rem;
                font-size: 0.8rem;
                padding: 0.3rem 0.6rem;
            }

        }
    }
}
.title_quiz{
    font-weight: 700;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #474747;
}

@media (max-width: 920px) {
    .container_quiz{
        margin: 1rem 0; 
    }
    .card_quiz{
        >div{
            >div{
                p, label{
                    padding: 0.3rem 0.8rem;
                    font-size: 0.7rem; 
                }
            }
        }
    }

    .answeredForms{
        margin: 1rem 0;
        align-items: center;
        .container_answered{
            display: flex;
            gap: 8rem;
            width: 100%;
            padding: 0 1rem;
            flex-direction: row;
            justify-content: flex-start;

            align-items:center;
        }
    }
    .card_form{
        height: 350px;
        width: 100%;
        padding: 0; 
        border: none;
        ul li{
            font-size: 0.6rem;
        }
    }
    .leftArrow, .rightArrow{
        img{
            width: 18px;
            height: 18px;
        }
    }
    .leftArrow{
        left: 0.5rem;
    }
    .rightArrow{
        right: 0.5rem;
    }
    .titleForm{
        height: 25%;
        margin-left: -1rem;
        .questioIdDescrition{
            font-weight: 900;
            font-size: 0.9rem;
            color: #474747;
        }
    }
    .matchingSection{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .matchingSectionResponsive{
        flex-direction: column;
    }
    .container_matching_card{
        width: 100%;
    }
    .hidenSection {
        display: none;
     }
    .elasticPrice {
        margin-bottom: 1rem !important;
        > div {
            padding: 0;
            h3{
                display: block;
                font-size: 0.75rem;
            }
            .bar {
                width: 100%;
                h3{
                    font-size: 0.65rem;
                }
            }
            h4 {
                margin-bottom: 2rem;
            }
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
        }
    }
    .indetificationResposive {
        display: flex;
        margin-bottom: 1rem;
        svg{
            width: 18px;
        }
        .ContentInformationUser {
            display: flex;
            flex-direction: column;          
            gap: 0.3rem;
            div:first-child {
                display: flex;
                gap: 0.5rem;
            }
            div {
                display: flex;
                gap: 0.5rem;
            }
        }
        .BehaviorUserResponsive {
            display: flex;
            flex-direction: row;
            margin-top: 0.5rem;
            width: 100%;
            justify-content: space-between;
            div{
                display: flex;
                align-items: center;
            }
            h4 {
                margin-bottom: 0;
            }
        }
    }

    .insightsDiv {
        flex-direction: column;
    }
    .title {
        display: none;
    }
    .title_responsive {
        display: block;
    }
    .insights,
    .insights2,
    .insights3,
    .insightsDiv {
        gap: 1rem;
        margin: 0;
    }
    .containerSearch {
        width: 91%;
        margin-top: 3rem;
    }
    .insights {
        flex-direction: column;
        margin-top: 1rem;

        .contentColor {
            width: 100%;
            div {
                align-items: flex-end;
            }
            > div h3 {
                margin-bottom: 0.5rem;
            }

            > div h2 {
                font-size: 1.3rem;
            }
        }
    }
    .insightsDiv {
        h4 {
            word-break: break-all;
        }
    }
    .insights2 {
        gap: 0.5rem;
        flex-direction: column;
        margin-bottom: 1rem;
        margin-top: 1rem;
        padding: 0 0.5rem;
        > div {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 0.5rem;

            section {
                flex-direction: row;
                border: 1px solid #acacac7e;
                border-radius: 0.2rem;

                div {
                    width: auto;
                }
                h4 {
                    font-size: 0.6rem;
                }
            }
            h4 {
                font-size: 0.8rem;
            }
            h2 {
                margin-top: 0;
                font-size: 1.3rem;
            }
        }
    }
    .insights3 {
        flex-direction: column;
        margin-bottom: 1rem;
        margin-top: 1rem;
        .contentColor {
            display: none;
            > div h3 {
                margin-bottom: 0.5rem;
            }
        }
        .contentResponsive {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .contentColor {
                display: flex;
            }
        }
    }

    .showMore {
        svg {
            display: block;
        }
        span {
            display: none;
        }
    }
    .containerSearch {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-bottom: 15px;
        border-bottom: 0;
        h3 {
            margin-bottom: 0.5rem;
        }
        > div {
            width: 100%;
        }
    }
    h4 {
        font-size: 0.7rem;
    }

    .contentColor {
        > div {
            flex-direction: column;
            align-items: flex-start;
            h3 {
                font-size: 0.75rem;
            }
        }
    }
    .styleAccreditedUsers {
        display: none;
        align-items: flex-start;
        flex-direction: row;
        border: 0.5px solid rgba(128, 128, 128, 0.2);
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
        border-radius: 0;
        border-left: 0;
        border-right: 0;
        padding: 1rem;
        margin-bottom: 1rem;
        h1 {
            font-size: 1rem;
        }
        h6 {
            font-size: 0.65rem;
        }
        img {
            width: 15%;
            height: 5%;
            object-fit: contain;
            min-height: 10px;
            margin-bottom: auto;
        }
        .BehaviorUser {
            display: flex;
            justify-content: flex-end;
            gap: 0.5rem;
            h4 {
                color: rgb(0, 0, 0);
                font-weight: 900;
            }
        }
    }

    .styleAccredited {
        background-color: #e1e1e1;
        border: 0.5px solid rgba(128, 128, 128, 0.2);
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
        border-radius: 0;
        border-left: 0;
        border-right: 0;
        margin-top: 1rem;
        img {
            object-fit: contain;
        }
        // .BehaviorUser{
        //     display: flex;
        //     flex-direction: column-reverse;
        //     margin-top: -30%;
        //     h4{
        //     color: rgb(0, 0, 0);
        //     font-weight: 900;
        //     }
        // }
        h1 {
            font-size: 1.1rem;
        }
        > div {
        }
    }
}


@media (max-width: 500px) {
    .styleAccredited {
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem 1rem;
        img {
            height: 55%;
            object-fit: contain;
            min-height: 60px;

        }
    }
    .insights {
        .contentColor {
            div {
                h2 {
                    font-size: 1.3rem;
                }
            }
        }
    }
    .insights2 {
        div {
            h2 {
                font-size: 0.8rem;
            }
            h4 {
                font-size: 0.63rem;
            }
        }
    }
    .cardPersonalAdvisor {
        min-height: 280px;
        div p {
            font-size: 0.5rem;
        }
        img {
            object-fit: cover;
            height: 95%;
        }
    }
}
