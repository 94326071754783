.gaussContainer{
    left:0;
    position: absolute;
    width: 100%;
    height: 60%;
    bottom: 3.25rem;
    .min, .max, .mean {
        display: flex;
    }
    .min{
        position: absolute;
        left: 25.5%;
        background-color: #acacac;
        width: 1px;
        bottom: 0;
        height: 100%;
        z-index: 1;
        justify-content: flex-end;
        align-items: flex-end; 
        label{
            margin-bottom: -15px;
            color: #575757;
            font-weight: 700;
        }
    }
    .mean{
        justify-content: center;
        position: absolute;
        left: 50%;
        background-color: #555555;
        width: 1px;
        bottom: 0;
        height: 110%;
        z-index: 1;
        label{
            margin-top: -0.755rem;
            color: #575757;
            font-weight: 700;
        }
    }
    .max{
        justify-content: flex-start;
        align-items: flex-end; 
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: #acacac;
        left:74.7%;
        bottom: 0;
        z-index: 1;
        label{
            margin-bottom: -0.75rem;
            color: #575757;
            font-weight: 700;
        }
    }
}

@media (max-width: 1444px){
    .gaussContainer{
        bottom: 2.25rem;
        .max, .min{
            label{
                margin-bottom: -1.2rem; 
            }
        }
    }
}