.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
}
.ClothingsWrapper {
    margin-top: 5vh;
    width: 60vw;
    border-radius: 0.3rem;
    height: 80vh;
    background-color: white;
    max-width: 1000px;
    .modalBody {
        display: flex;
        width: 100%;
        padding: 1% 5%;
        height: 78%;
        overflow-y: scroll;
        flex-direction: column;

        .wrapperInfo {
            display: flex;
       
            margin-top: 2rem;
            margin-bottom: 1rem;
            > div {
                height: 25vh;
                div {
                    width: 100%;
                    height: 100%;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
            .modalInformation {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;

                div {
                    display: flex;
                    flex-direction: column;
                }
                h4 {
                    text-transform: uppercase;
                }
            }
            .modalInformation .modalInformation_footer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                div {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    flex-direction: column;

                    div {
                        display: flex;
                        justify-content: flex-start;
                        height: 35px;
                        flex-direction: row;

                        > div {
                            margin-top: 8px;
                            min-width: 30px;
                            max-width: 35px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0px 4px;
                            position: relative;
                            border: 1px solid black;
                            border-radius: 4px;
                            color: black;
                            margin-right: 8px;
                            background-color: whtie;
                            cursor: pointer;
                        }
                        > div:first-child {
                            background-color: black;
                            color: white;
                            border: 1px solid black;
                        }
                    }
                }
                a {
                    width: 140px;
                    font-weight: 900;
                    font-size: 14px;
                    color: red;
                    border: 1px solid red;
                    border-radius: 4px;
                    padding: 6px 0;
                    background-color: transparent;
                }
                h3 {
                    font-size: 16px;
                }
            }
        }
    }
    .modalFooter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #dee2e6;
        width: 100%;
        margin-left: auto;
        height: 10vh;
        padding: 1% 5%;
        padding-left: 7rem;
        div {
            p {
                text-align: end;
                font-size: 12px;
            }
            h4 {
                font-size: 20px;
                text-align: end;
            }
        }
        a {
            height: 60%;
            width: 55%;
            background-color: black;
            color: white;
            border: none;
        }
    }
    .modalHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 8vh;
        border-bottom: 1px solid #dee2e6;
        p {
            text-transform: uppercase;
        }
    }
}
.modalBodyOne {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: 0.3rem;
    overflow: hidden;

    .wrapperInfoOne {
        display: flex;
        position: relative;
        height: 100%;
    }
    .wrapper_image {
        position: relative;
        width: 100%;
        height: 100%;

        .additional_images {
            display: flex;
            flex-direction: column;
            left: 2.5%;
            top: 3%;
            position: absolute;
            img {
                height: 75px;
                width: 75px;
                border-radius: 4px;
                margin-bottom: 16px;
                overflow: hidden;
                box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 3%);
                cursor: pointer;
                margin-left: 2%;
                object-fit: cover;
                margin-right: 2%;
            }
        }

        .principalImage {
            width: 100%;
            height: 100%;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
    .modalInformation {
        width: 82%;
        margin: 3%;
        background-color: white;
        position: relative;
        div {
            display: flex;
            flex-direction: column;
            h4 {
                font-size: 17px;
                text-transform: uppercase;
            }
            p{
                font-size: 14px;
            }
        }
        .modalInformation_selector {
            display: flex;
            div {
                display: flex;
                flex-direction: column;
                h3{
                    margin-top: 1rem;
                    font-size: 13px;
                    text-transform: uppercase;
                }
                p{
                    font-size: 14px;
                    font-weight: 400;
                    word-spacing: 2px;
                    letter-spacing: 1px;
                }
                div {
                    display: flex;
                    margin-bottom: 2%;
                    flex-direction: row;
                    
                    div {
                        margin-top: 8px;
                        min-width: 45px;
                        font-size: 0.85rem;
                        min-height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding:4px;
                        position: relative;
                        border: 1px solid black;
                        margin-right: 8px;
                        background-color: white;
                        cursor: pointer;
                    }
                }
            }
        }

        .modalInformation_footer {
            display: flex;
            width: 100%;
            position: absolute;
            bottom: 0;
            margin-top: auto;
            flex-direction: column;
            h3{
                margin-bottom: 8px;
                font-size: 17px;
                word-spacing: 2px;
                letter-spacing: 1px;
                color: black;
                font-weight: 700;
            }

            div{
                display: flex;
                flex-direction: row;
                width: 100%;
                padding-bottom: 2.5%;
                height: 20vh;

                img{
                    border-radius: 8px;
                    vertical-align: middle;
                    overflow: hidden;
                    margin-right: 1%;
                    margin-left: 1%;
                    border-style: none;
                    width: 31%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                background-color: black;
                cursor: pointer;
                color: white;
                width: 100%;
                min-height: 45px;
                font-weight: 700;
                border: none;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-size: 16px;
            }
        }
    }
}
.closeModal{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 5;
    cursor: pointer;
}
@media (max-width: 1440px) {
    .ClothingsWrapper {
        .modalFooter {
            padding-left: 5.5rem;
            a {
                height: 100%;
            }
        }
    }
}
@media (max-width:920px){
    .ClothingsWrapper{
        width: 100%;
        border-radius: 0;
        margin-top: 0;
        height: 100%;
        max-width: none;
    }
    .modalBodyOne{
        border-radius: 0;
        .wrapper_image{
            height: 70%;
        }
        .wrapperInfoOne{
            flex-direction: column;
        }
        .modalInformation{
            display: flex;
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 3%;
            flex-direction: column;
            div h4{
                font-size: 0.7rem;
            }
            div p {
                font-size: 0.7rem;
            }
            div h3{
                font-size: 0.7rem;
            }
            .modalInformation_footer {
                position: relative;
                a{
                    height: 35px;
                    min-height: 35px;
                    letter-spacing: 0px;
                    font-size: 0.7rem;
                    font-weight: 500;
                }
            }
            .modalInformation_selector{
                div div div {
                    border: 1px solid black;
                    height: 35px;
                    width: 35px;
                    font-size: 0.7rem;
                    min-width: 0px;
                    min-height: 0px;
                }
            }
          
        }
    }
  
}
