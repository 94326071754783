@keyframes is-rotating {
    to {
        transform: rotate(1turn);
    }
}

@keyframes fade-in {
    0% {
        transform: translateX(-50px);
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
.spinner{
    svg{
        animation: is-rotating 1s infinite;
    }

}
:root {
    --greenLight: #52b303;
    --greyDark: rgb(53, 53, 53);
    --greyLight: rgb(100, 100, 100);
}

.toggle {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 200px;
    height: 50px;
}
.switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: -10px;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #ffcc00;
}

input:focus + .slider {
    box-shadow: 0 0 1px #ffcc00;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}ner

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.formatDisplayFlex{
    display: flex;
    align-items: center;
    gap: 0.2rem;
    justify-content: center;
    
}
.container {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    width: 65vw;
    min-height: 96vh;
    margin-left: 22vw;
    min-width: 972px;
    margin-top: 4vh;
    select{
        background-color: white;
        border: 1px solid var(--greenLight);
    }

    > span {
        position: absolute;
        margin: auto;
        top: 40vh;
        svg {
            animation: is-rotating 1s infinite;
        }
    }


    hr {
        border: 0;
        border-top: 1px solid rgba(128, 128, 128, 0.252);
        width: 100%;
    }

    section {
        padding: 1.5rem 1.5rem;
        background-color: white;
        border: 1px solid rgba(128, 128, 128, 0.38);
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
        border-radius: 0.4rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        width: 100%;
        margin-bottom: 1.5rem;

        h2 {
            font-size: 1.5rem;
            font-weight: 900;
            color: rgb(255, 204, 0);
        }
        > label {
            color: rgb(255, 204, 0);
            font-size: 1.1rem;
        }
        h3 {
            color: rgb(94, 94, 94);
            font-size: 0.9rem;
            margin-bottom: 2rem;
            font-weight: 800;
        }
        h6 {
            color: rgb(100, 100, 100);
            font-size: 0.7rem;
            font-weight: 700;
        }
        h4 {
            color: rgb(53, 53, 53);
            font-size: 1.2rem;
            margin-bottom: 1rem;
            font-weight: 900;
        }
        span {
            color: rgb(53, 53, 53);
            font-weight: 800;
            font-size: 0.7rem;
        }
        .button{
            background-image: linear-gradient(to top, #61b361, #96db00);
            margin: auto;
        }
        .buttonRed{
            color: white;
            background-image: linear-gradient(to top, #A20000, #d80606);
            margin: auto;
        }
        .contentUserCard {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            gap: 2rem;
            .userCard {
                display: flex;
                gap: 1rem;
                border-radius: 0.4rem;
                width: 28vw;
                height: 8vw;
                padding: 1rem;
                box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
                div {
                    width: 100%;
                    display: flex;
                    gap: 0.5rem;
                    flex-direction: column;
                    h4 {
                        margin: 0;
                        width: 100%;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        h5 {
                            cursor: pointer;
                            border-radius: 0.2rem;
                            background-color: #b50000;
                            color: white;
                            padding: 0.5rem 1rem;
                        }
                    }
                }
            }
        }

        .labelBusiness {
            display: flex;
            gap: 5rem;
            justify-content: space-between;
            width: 100%;
            input {
                height: 35px;
                padding-left: 15px;
                width: 40%;
                background-color: #ffffff;
                border: none;
                color: rgb(75, 75, 75);
                border: 1px solid #7a7a7a50;
                border-radius: 0.4rem;
            }
            p {
                height: 35px;
                display: flex;
                align-items: center;
                color: rgb(75, 75, 75);
                width: 70%;
                font-size: 14px;
                padding-left: 15px;
                background-color: #e9ecef;
                border: none;
                border: 1px solid #7a7a7a50;
                border-radius: 0.4rem;
            }
            > input {
                height: 35px;
                display: flex;
                align-items: center;
                color: rgb(75, 75, 75);
                width: 70%;
                font-size: 14px;
                min-width: 203px;
                padding-left: 15px;
                background-color: #e9ecef;
                border: none;
                border: 1px solid #7a7a7a50;
                border-radius: 0.4rem;
            }
            label {
                display: flex;
                width: 25%;
            }
            h3 {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 0.5rem;
                justify-content: space-between;
            }
            div {
                display: flex;
                width: 70%;
                gap: 1rem;
            }
            button {
                margin-left: auto;
                height: 35px;
                border: none;
                cursor: pointer;
                border-radius: 0.4rem;
                border: 1px solid rgb(190, 190, 190);
                color: rgb(51, 51, 51);
                font-weight: 500;
                width: 20%;
                min-width: 200px;
            }
            .language {
                margin-left: auto;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 1rem;
                gap: 0.5rem;
                width: 60%;

                img {
                    max-height: 36px;
                    min-height: 16px;
                }
                select {
                    border: 1px solid rgb(190, 190, 190);
                    border-radius: 0.4rem;
                    height: 35px;
                    width: 27%;
                    min-width: 160px;
                }
            }
            .inputFile {
                justify-content: flex-end;
                input {
                    padding-left: 0;
                    width: 117px;
                    height: 33px;
                }
                button {
                    height: 35px;
                    border: none;
                    margin-left: 0;
                    border-radius: 0.4rem;
                    border: 1px solid rgb(190, 190, 190);
                    color: rgb(51, 51, 51);
                    font-weight: 500;
                    width: 20%;
                }
            }
            input::-webkit-file-upload-button {
                border: none;
                background-color: rgb(75, 75, 75);
                color: white;
                font-weight: 500;
                border-radius: 0.4rem;
                transition: transform 0.2s;
                cursor: pointer;
                padding:0.5rem;
            }
            input::-ms-file-upload-button {
                border: none;
                background-color: rgb(75, 75, 75);
                color: white;
                font-weight: 500;
                border-radius: 0.4rem;
                transition: transform 0.2s;
                cursor: pointer;
                padding:0.5rem;
            }
            input::file-selector-button {
                border: none;
                background-color: rgb(75, 75, 75);
                color: white;
                font-weight: 500;
                width:100%;
                height: 100%;
                border-radius: 0.4rem;
                transition: transform 0.2s;
                cursor: pointer;

            }
        }
    }
}

.contentSection {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.5rem;


    width: 100%;
    h2 {
        font-weight: 900;
        svg {
            fill: rgb(255, 204, 0);
            animation: is-rotating 1s infinite;
        }
    }
    svg {
        fill: rgb(255, 204, 0);
        animation: is-rotating 1s infinite;
    }
    h5 {
        font-weight: 900;
        font-size: 0.75rem;
        color: rgb(70, 70, 70);
    }
}
.div_cards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    flex-wrap: wrap;
}
.miniCard{
    display: flex;
    justify-content:flex-start;
    padding: 0 1.5rem;
    gap: 0.5rem;
    div{
        h4{
            font-size: 1.2rem;
        }
    }
}

.section1 {
    .card {
        margin-bottom: 1rem;
        transform: scale(0.9);
        font-size: 2rem;
    }

    .headerSection {
        display: flex;
        justify-content: space-between;
        width: 100%;
        span {
            cursor: pointer;
        }
        h4 {
            color: rgb(53, 53, 53);
            font-size: 1.2rem;
            margin-bottom: 1rem;
            font-weight: 900;
        }
    }
    .loaderMore {
        display: flex;
        background-color: "white";
        border: 1px solid rgb(194, 194, 194);
        border-radius: 0.2rem;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-top: 5rem;
        height: 35px;
        width: 200px;
        svg {
            animation: is-rotating 1s infinite;
        }
    }
}
.loaderMore {
    display: flex;
    background-color: "white";
    border: 1px solid rgb(194, 194, 194);
    border-radius: 0.2rem;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 35px;
    width: 200px;
    svg {
        animation: is-rotating 1s infinite;
    }
}

.contentInfoView {
    margin-right: auto;
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
}

.title {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    margin-top: 3rem;
    margin-right: auto;
    font-weight: 900;
    color: rgb(34, 34, 34);
}

.loader {
    z-index: 1;
    position: fixed;
    left: 50%;
    right: 50%;
    top: 50%;
    transform: scale(3);
    svg {
        animation: is-rotating 1s infinite;
    }
}

.refetchingLoader {
    svg {
        animation: is-rotating 1s infinite;
    }
}
.tableList {
    width: 100%;
    border-collapse: collapse;

    tr {
        color: var(--greyDark);
    }

    thead {
    }
    thead {
        tr {
            th {
                padding:0.5rem;
                font-size: 0.9rem;
                font-weight: 900;
                border-bottom: 1px solid rgb(226, 226, 226);
            }
        }
    }
    tbody {
        td {
            padding: 1rem;
            text-align: center;
            animation: fade-in 0.5s 1 forwards;
            opacity: 0;
            h6 {
                margin: auto;
                font-weight: 900;
                font-size: 0.8rem;
            }
            a {
                text-decoration: none;
                color: white;
                font-size: 0.7rem;
                font-weight: 500;
            }
        }
    }

    .dataPlan {
        width: 80%;
        background-color: var(--greyLight);
        padding: 0.3rem;
        color: white;
        font-size: 0.7rem;
        font-weight: 500;
        border-radius: 0.2rem;
    }
}
.HeaderWebInfo {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 1rem;
    > div {
        > h6 {
            margin-top: 0.3rem;
            margin-left: 0.2rem;
            display: flex;
        }
    }
    select {
        border: none;
        background-color: white;
        font-weight: 900;
        color: var(--greyDark);
        font-size: 1.5rem;
    }
}
.contentIntegration {
    display: flex;
    width: 950px;
    margin: 0 auto;
    position: relative;
    height: 700px;
    align-items: center;
    padding: 1rem;
    gap: 2rem;
    border-radius: 0.4rem;
    overflow: hidden;
    text-align: center;
    h6 {
        margin: auto;
        margin-bottom: 0.5rem;
        width: 80%;
        color: var(--greyDark);
        font-weight: 900;
    }
    p {
        cursor: pointer;
        color: var(--greyLight);
        margin: auto;
        width: 90%;
        font-size: 0.7rem;
    }
    .sliderInvetory {
        position: relative;
        min-width: 280px;
        opacity: 0.3;
        transition: all ease 0.3s;
        background-color: white;
        height: 450px;
        border-radius: 0.8rem;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
        img {
            object-fit: cover;
            width: 100%;
            height: 50%;
        }
    }
    .slideAtual {
        opacity: 1;
        z-index: 1;
        transition: all ease 0.3s;
        transform: scale(1.1);
        position: relative;
        min-width: 280px;
        background-color: white;
        height: 450px;
        border-radius: 0.8rem;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
        img {
            cursor: pointer;
            object-fit: cover;
            width: 100%;
            height: 50%;
        }
    }

    .ribbon {
        width: 140px;
        height: 150px;
        transform: scale(0.75);
        overflow: hidden;
        position: absolute;
    }
    .ribbon::before,
    .ribbon::after {
        position: absolute;
        z-index: -1;
        content: "";
        display: block;
        border: 5px solid #96db00;
    }
    .ribbon span {
        position: absolute;
        display: block;
        width: 225px;
        padding: 10px 0;
        background-image: linear-gradient(to top, #61b361, #96db00);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        color: #fff;
        font: 700 14px/1 "Lato", sans-serif;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        text-transform: uppercase;
        text-align: center;
    }

    .ribbonTopRight {
        top: -25px;
        right: -25px;
    }
    .ribbonTopRight::before,
    .ribbonTopRight::after {
        border-top-color: transparent;
        border-right-color: transparent;
    }
    .ribbonTopRight::before {
        top: 0;
        left: 18px;
    }
    .ribbonTopRight::after {
        bottom:28px;
        right: 0;
    }
    .ribbonTopRight span {
        left: -25px;
        top: 30px;
        transform: rotate(45deg);
    }
}
.ribbon {
    width: 140px;
    height: 120px;
    transform: scale(0.7);
    overflow: hidden;

    position: absolute;
}
.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #96db00;
}
.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 0;
    background-image: linear-gradient(to top, #61b361, #96db00);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    font: 700 14px/1 "Lato", sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
}

.ribbonTopRight {
    top: -25px;
    right: -28px;
}
.ribbonTopRight::before,
.ribbonTopRight::after {
    border-top-color: transparent;
    border-right-color: transparent;
}
.ribbonTopRight::before {
    top: 0;
    left: 20px;
}
.ribbonTopRight::after {
    bottom: 0;
    right: 0;
}
.ribbonTopRight span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
}


.buttonBack {
    height: 50px;
    font-size: 2.5rem;
    border: none;
    background-color: rgba(224, 224, 224, 0.459);
    border-radius: 50%;
    width: 50px;
    position: absolute;
    z-index: 2;
    bottom: 50%;
    left: 100px;
    margin: auto;
    color: black;
}
.buttonNext {
    height: 50px;
    font-size: 2.5rem;
    border: none;
    background-color: rgba(224, 224, 224, 0.459);
    border-radius: 50%;
    width: 50px;
    position: absolute;
    z-index: 2;
    bottom: 50%;
    right: 100px;
    margin: auto;
    color: black;
}
.button {
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 0.2rem;
    font-weight: 700;
    color: white;
    background-image: linear-gradient(to top, #61b361, #96db00);
    cursor: pointer;
}
.buttonRed{
    width: 200px;
    height: 40px;
    border-radius: 0.2rem;
    font-weight: 700;
    border: none;
    color: white;
    background-image: linear-gradient(to top, #A20000, #d80606);
    margin: auto;
    cursor: pointer;
}
.buttonBack,
.buttonNext {
    cursor: pointer;
    transition: all ease 0.2s;
    svg {
        fill: rgba(131, 129, 129, 0.521);
        &:hover {
            fill: rgba(128, 128, 128, 1);
        }
    }
}
.tableGraphic {
    display: flex;
    gap: 1rem;
    width: 100%;
    flex-direction: row;

    section {
        width: 70%;
        h4 {
            margin-top: 0;
        }
    }
    .containerTable{
        width: 35%;
        height: 50vh;
        padding: 1rem;
        div {
        display: flex;
        align-items: center;
        background-color: rgb(255, 255, 255);
        border-radius: 0.4rem;
        height: 100%;
        width: 100%;
        table {
            font-size: 0.8rem;
            width: 100%;
            height: 100%;
            margin: auto;
            margin-top: 0;

            tr td,
            th {

                text-align: end;
                &:first-child {
                    min-width: 80px;
                    color: rgb(80, 80, 80);
                    font-weight: 700;
                    text-align: start;
                }
            }
            th {
                width: 20%;
                color: rgb(80, 80, 80);
                font-weight: 700;
                padding: 0.3rem;
            }
            td {
                color: var(--greyDark);
                font-weight: 900;
            }
        }
    }
}
}
.dropDownCalendar {
    td {
        div {
            &:hover {
                background-color: #575757;
            }
        }
    }
}
.containerMessage{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    z-index: 6;
    left: 0;
    top: 0;
    height: 100vh;
    position: fixed;
    background-color: rgba(8, 8, 8, 0.705);
    .containerMessage_info{
        border-radius: 0.4rem;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        gap: 2rem;
        background-color: #fff;
        width: 40%;
        min-height: 300px;
        div{
            display: flex;
            gap: 2rem;
            width: 100%;
        }
        label{
            width: 10%;
            font-weight: 900;
            color: rgb(87, 87, 87);
        }
        button{
            margin: auto;
            width: 250px;
            border: none;
            color: white;
            font-weight: 700;
            border-radius: 0.2rem;
            height: 35px;
        }
        h2{
            margin: 0;
        }

    }
}
.queriesInsightsTable{
    width: 100%;
    >div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: rgb(37, 37, 37);
        border-bottom: 1px solid rgb(175, 175, 175);
        padding-bottom: 0.3rem;
        padding-top:1rem;
        div{
            display: flex;
            gap: 0.5rem;
        }

    }
    .content_information{
        width: 50%;
        div{
            min-width: 50%;
            color:gray;
        }
        svg{
            margin: auto;

        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        color: gray;
    }
}

.addUser{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 3;
    top: 0;
    left:0;
    display: flex;
    align-items: center;
    border: 1px solid #afafaf;
    justify-content: center;
    h4{
        font-size: 1.4rem;
        color: #3d3d3d;
        font-weight: 900;
        margin-bottom: 1rem;
        margin-right: auto;
    }
    .form{
        padding: 2rem;
        background-color: white;
        width: 53%;
        height: 65vh;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border: 1px solid #afafaf;
        box-shadow: 1px 1px 15px 10px rgba(0, 0, 0, 0.2);
        border-radius: 0.8rem;
        gap: 1rem;
        flex-direction: column;

        .closeButton{
            height: 25px;
            width: 25px;
            border: none;
            border-radius: 0.2rem;
            color: white;
            cursor: pointer;
            transition: all ease 0.2s;
            font-weight: 900;
            background-color: #000000d2;
            &:hover{
                opacity: 0.8;
            }
        }

        div{
            height: 20%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            label{
                font-weight: 700;
                color: #3d3d3d;
            }
            input{
                border: none;
                border: 1px solid #b2b2b2;
                border-radius: 0.4rem;
                background-color: #f4f4f4;
                height: 45%;
                padding-left: 0.5rem;
                outline: black;
                width: 100%;
            }
            select{
                border-radius: 0.4rem;
                height: 50%;
                width: 20%;
                border: 1px solid rgba(128, 128, 128, 0.8);
            }
        }

    }
}
.history{
    
}
@media (max-width: 1720px) {

    .container{
        section h2{
            font-size: 1rem;
        }
        .miniCard{
            h4{
                font-size: 1rem;
            }
        }
    
    }
 
    .container section {
        h6,
        h5,
        p,
        a {
            font-size: 0.7rem;
        }
        h4{
            font-size: 1rem;
        }
        .headerSection h4{
            font-size: 1rem;
        }
    }
    td {
        font-size: 0.7rem;
    }
    th {
        font-size: 0.6rem;
    }
    .tableList{
        th{
            padding: 0.5rem;
        }
        td{
            padding: 0.3rem 1.2rem;
        }
    }
    .contentIntegration {
        width: 580px;
        height: 500px;
        gap: 1rem;
        .sliderInvetory {
            position: relative;
            min-width: 180px;
            opacity: 0.3;
            height: 350px;
        }
        .slideAtual {
            position: relative;
            min-width: 180px;
            height: 350px;
        }
    }
    .buttonBack {
        height: 50px;
        font-size: 2.5rem;
        border: none;
        background-color: rgba(224, 224, 224, 0.459);
        border-radius: 50%;
        width: 50px;
        position: absolute;
        z-index: 2;
        bottom: 45vh;
        left: 100px;
        margin: auto;
        color: black;
    }
    .buttonNext {
        height: 50px;
        font-size: 2.5rem;
        border: none;
        background-color: rgba(224, 224, 224, 0.459);
        border-radius: 50%;
        width: 50px;
        position: absolute;
        z-index: 2;
        bottom: 45vh;
        right: 100px;
        margin: auto;
        color: black;
    }
    .tableGraphic{
        >div{

        }
    }
    .buttonNext, .buttonBack{
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            width: 80%;
            height: 80%;
        }
    }

}


@media (max-width:920px){
  
    .formatDisplayFlex{
        height: auto;
        flex-direction: column;
        margin-bottom: 1rem;
    }
    .container{
        width: 100vw;
        margin-left: 0;
        margin: auto;
        min-width: 265px;
        
        section{
            flex-direction: column;
            margin-bottom: 0rem;
            font-size: 0.65rem;
            border-left: none;
            border-right: none;
            border-radius: 0;
            padding: 1rem;

            div{
                font-size: 0.8rem;
                
            }
            h4{
                font-size: 0.66rem;
            }
            div h4{
                font-size: 0.66rem;
            }
            .headerSection h4{
                font-size: 0.66rem;
            }
        }
        .history{
            margin-top: 1rem;
        }
        .section1{
            .contentSection{
                .div_cards{
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    row-gap: 0.5rem;
                    column-gap: 0.5rem;
                    >div{
                        margin:auto;
                    }
                }
            }
        }
    }
    hr{
        width:40%;
    }
    .title{
        font-size: 0.75rem;
    }
    .contentColor{
        height: auto;
    }

}
@media (max-width:600px){
    .container{
        padding-bottom: 8rem;
        .section1{
            .contentSection{
                .div_cards{
                    grid-template-columns: 1fr 1fr ;
                    >div{
                        margin:auto; 
                    }
                }
            }
        }   
    }
}