.container{
   display: none;
}
.activeLink{
    background-color:#ededed;
}
@media (max-width: 720px) {
    .container{
        display: flex;
        justify-content: space-evenly;
        position: fixed;
        
        bottom: 0;
        left: 0;
        
        width: 100%;
        border-top: 1px solid #e0e0e0;
        background-color: #FAFAFA;
        z-index: 4;
        a{
            display: flex;
            align-items: center;
            
            flex: 1;
            
            justify-content: space-between;
            flex-direction: column;    
            
            text-decoration: none;
            font-size: 0.7rem;
            color: #3d3d3d;
            padding: 0.5rem 0.5rem;
            
            cursor: pointer;
        }
    }
}