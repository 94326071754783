.contentSearch {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.container_clothings {
    min-height: 64vh;
    width: 100%;
     section{
        width: 95%;
        margin-top: 2rem;
        gap: 0.5rem;
        div {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            h3 {
                margin-bottom: 0.2rem;
                width: 10%;
            }
            p {
                display: flex;
                align-items: center;
                padding-left: 1rem;
                width: 90%;
                background-color: #eeeeee;
                height: 45px;
                font-size: 0.8rem;
                font-weight: 400;
            }
        }
    }
    .header_details{
        display: flex;
        padding: 0 2rem;
        align-items: center;
        gap: 1rem; 
        margin-left: auto;
        p{
            font-weight: 800;
            font-size: 0.9rem;
            color: rgb(94, 94, 94);
        }
        >h3{
            color:#53b304;
        }
    }
    .info_request {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 2rem;
        background-color: #FFFFFF;
        margin-bottom: none;
        gap: 0.5rem;
        div {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            h3 {
                color: rgb(94, 94, 94);
                font-size: 0.9rem;
                font-weight: 800;
                width: 10%;
            }
            p {
                display: flex;
                align-items: center;
                padding-left: 1rem;
                width: 90%;
                background-color: #eeeeee;
                height: 45px;
                font-size: 0.8rem;
                font-weight: 400;
            }
        }

        .approval_icon{
            h3{
                text-align: center;
                width: 100%;
                color: #53b304;
            }
        }
    }


    .header_list {
        display: flex;
        align-items: flex-end;
        height: 106px;
        background-color: #fafafa;
        position: sticky;
        top: 0;
        z-index: 2;
        width: 95%;
        border-bottom: 1px solid #d7d7d7;
        box-shadow: 1px -10px 10px 8px rgba($color: #fafafa, $alpha: 1);
        padding-bottom: 1rem;
        justify-content: space-between;
        > h2 {
            margin-right: auto;
            font-size: 1.1rem;
        }

        button {
            height: 40px;
            width: 230px;
            border-radius: 4px;
            font-size: 0.75rem;
            border: none;
            color: white;
            font-weight: 700;
            background-color: #53b304;
            cursor: pointer;
            transition: all ease 0.3s;
            &:hover {
                background-color: #86be53;
            }
        }
    }

    .labelSelected {
        z-index: 1;
        position: absolute;
        right: 15px;
        top: 20px;
    }
}
.subTitle {
    display: flex;
    align-self: flex-start;
    margin: 2rem 0;
    margin-bottom: 1rem;
}
.container_filter {
    width: 100%;
    .filters {
        display: flex;
    }
}
.filters {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    min-width: 325px;
    padding-bottom: 2rem;
    align-self: flex-end;
    h3 {
        display: flex;
    }
    > div {
        display: flex;
        justify-content: flex-end;
        gap: 1.5rem;
        flex-wrap: wrap;
    }
}
.filter {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #EBEBEB;
    color: #4D4A4A;
    width: 40px;
    height: 40px;
    border: 0.5px solid #8080807e;
    border-radius: 0.2rem;
}

.clothing_list {
    margin: 0 auto;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    gap: 2rem;

    .clothingFormatColumns {
        display: grid;
        width: 95%;
        border: 0.5px solid #d7d7d7;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
        padding: 2rem;
        border-radius: 0.4rem;
        background-color: #ffffff;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2rem;
    }
    .container_request {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 0.5rem;
        >div{
            display: flex;
            margin-top: auto;
            align-items: center;
            justify-content: flex-start;
            gap: 0.5rem;
        }
        p {
            font-size: 0.8rem;
            font-weight: 500;
            color: #474747;
        }
        h4 {
            margin-left: auto;
        }
        select {
            width: 60px;
            height: 30px;
            border-radius: 0.2rem;
            text-align: center;
            outline: none;
            background-image: linear-gradient(to top, rgb(189, 189, 189), #ebebeb 40%);
            border-color: #808080;
        }
        input {
            width: 60px;
            height: 100%;
            outline: none;
            border: none;
            border-radius: 0.2rem;
            border:0.5px solid #808080;
            text-align: center;
        }
        svg {
            cursor: pointer;
            margin-left: auto;
        }
    }
    .card_request {
        display: flex;
        background-color: #ffffff;
        gap: 2rem;
        position: relative;
        img {
            height: 150px;
            border-radius: 0.4rem;
        }
    }
    .card_list {
        position: relative;
    }

}
.container_attendances {
    display: grid;
    width: 95%;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    .card_attendances {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.4rem;
        box-shadow: 1px 1px 5px 1px rgba($color: #000000, $alpha: 0.2);
        gap: 1rem;
        padding: 1rem 1rem;
        transition: all ease 0.2s;
        cursor: pointer;
        background-color: rgb(255, 255, 255);
        svg {
        display: flex;
        height: 100%;
        }
        > div {
            div {
                display: flex;
                margin-bottom: 0.3rem;
                
                gap: 0.2rem;
                h6 {

                    font-weight: 700;
                    color: #474747;
                }
            }
        }
        &:hover {
            filter: brightness(0.9);
        }
        .statusAttendance {
            margin-top: auto;
            margin-left: auto;
            color: white;
            font-weight: 500;
            padding: 0.5rem 1rem;
            border-radius: 0.2rem;
            font-size: 0.7rem;
            background-color: #fcb005;
        }
    }
}
.notFound {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    height: 60vh;
    font-size: 1rem;
    color: gray;
}
.labelPrice {
    margin-bottom: 1rem;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    color: white;
    font-weight: 700;
    font-size: 0.9rem;
    width: 70%;
    background-color: #ff4f4f;
}
.buttonMobile {
    display: none;
    z-index: 3;
}
.loadMore {
    width: 200px;
    height: 34px;
    margin-top: 34px;
    margin-bottom: 34px;
    background-color: white;
    border: 1px solid #bdbdbd;
}
.card_checked{
    border: 2.5px solid #53b304;
}
.approval_details{
    margin: 2rem 0;
    width: 95%;
    box-shadow: 1px 1px 5px 1px rgba($color: #000000, $alpha: 0.2);
    border: 0.5px solid #eeeeee;
    border-radius: 0.4rem;
    background-color: #FFFFFF;
    gap: 0rem;
    .clothingFormatColumns{
        width: 100%;
        border: none;
        box-shadow: none;
        h4{
            flex: 0.3;
        }
        p:first-child{
            font-weight: 700;
        }
    }
    .header_details{
        display: flex;
        padding: 0 2rem;
        align-items: center;
        gap: 1rem; 
        margin-left: auto;
        p{
            font-weight: 800;
            font-size: 0.9rem;
            color: rgb(94, 94, 94);
        }
        >h3{
            color:#53b304;
        }
    }
}
.approval_icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    p{
        width: 100%;
        background-color: none; 
    }

}
@media (max-width:1720px) {
    .clothing_list {
        .clothingFormatColumns {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media (max-width: 720px) {
    .contentSearch {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 1rem;
        margin-top: 3rem;
        h4 {
            display: none;
        }
        > div {
            width: 95%;
        }
    }
    .container_clothings {
        .card_request {
            h4 {
                text-align: end;
                font-size: 0.8rem;
                font-weight: 800;
            }
        }
    }
    .labelPrice {
        height: 20px;
        font-size: 0.8rem;
    }
    .filter {
        width: 50px;
        height: 50px;
    }

    .container_clothings {
        section {
            width: 100%;
            margin-top: 0rem;
            margin-bottom: 1rem;
            div {
                h3 {
                    width: 20%;
                }
                p {
                    height: 30px;
                    width: 75%;
                }
            }
        }
        .none{
            display: none;
        }

        .header_list {
            height: 60px;
            top: 40px;
            border-bottom: none;
            padding-left: 1rem;
            width: 100%;
            box-shadow: none;
            > button {
                display: none;
            }
        }
        .info_request {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 1rem;
            margin-top: 0rem;
            div {

                h3 {
                    color: rgb(94, 94, 94);
                    font-size: 0.9rem;
                    font-weight: 800;
                    width: 20%;
                }
                p {
                    height: 30px;
                    width: 75%;
                }
            }
        }
        
        .clothing_list {
            width: 100%;
            margin: 0 0;
            justify-content: center;
            .clothingFormatColumns {
                width: 100%;
                border-radius: 0;
                padding: 1rem;
                border-left: none;
                border-right: none;
                padding-bottom: 12rem;
                margin-bottom: 0;
                grid-template-columns: 1fr;
            }
        }
    }

    .container_attendances {
        width: 100%;
        margin-bottom: 12rem;
        grid-template-columns: 1fr;
        .card_attendances {
            border-radius: 0;
            gap: 2rem;
            .statusAttendance {
                padding: 0.3rem 0.6rem;
                font-size: 0.6rem;
            }
        }
    }

    .approval_details{
        padding-top: 4rem;
        padding-bottom: 2rem;
        .header_details{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-right: auto;
            margin-left: 0;
            padding: 0;
            padding: 1rem;
        }
    }

    .container_filter {
        display: none;
    }
    .filters {
        display: flex;

        flex-direction: column;
        width: 100%;
        h3 {
            align-self: flex-start;
            font-size: 1rem;
            margin-left: 2rem;
            color: #676767;
        }
        > div {
            margin-right: auto;
            justify-content: center;
            gap: 2rem;
        }
        
    }

    .buttonMobile {
        display: flex;
        position: fixed;
        bottom: 6rem;
        align-items: center;
        justify-content: center;
        background-color: #fcb005;
        color: white;
        font-weight: 900;
        border: none;
        width: 90%;
        height: 50px;
    }
    .loadMore{
        margin-bottom: 10rem;
    }
    .notFound{
        font-size: 1.2rem;
    }
    .approval_request{
        margin-bottom: 1rem;
    }
     .none{
            display: none;
        }
        
}
