*{
  padding: 0;
  margin:0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

body::-webkit-scrollbar-thumb{
  background-color: #151414;
  border-radius: 0.5rem;

}
body::-webkit-scrollbar{
background-color: #fafafa;
width: 10px;
}
body{
  background-color: #fafafa;
}

h2{
  color: rgb(87, 87, 87);
}
h3{
  color: rgb(29, 29, 29);
}

.App {
  text-align: center;
}
.notFound{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  font-size: 1.5rem;
  color: gray;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

