@keyframes is-rotating {
    to {
        transform: rotate(1turn);
    }
}

@keyframes fade-in {
    0%{
        transform:translateX(-50px)
    }
    100%{
        transform:translateX(0);
        opacity: 1;
    }
}


li {
    list-style: none;
}
.card {
    background-color: white;
    border: 0.5px solid rgba(128, 128, 128, 0.2);
    animation: fade-in 0.5s 1 forwards;
    opacity: 0;
    transition: all ease 0.4s;
    display: flex;
    position: relative;
    height: 11vw * 2;
    min-height: 350px;
    min-width:150px;
    width: 11vw;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 0.4rem;
    transform: scale(0.9);
}

.loadingUpdate{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.829);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    svg{
        fill: rgb(83, 83, 83);
        animation: is-rotating 1s infinite
    }
}

.session1 {
    display: flex;
    position: relative;
    overflow: hidden;
    height:100%;

    .imageCheckBox {
        position: absolute;
        width: 15px;
        height: 15px;
        top: 15px;
        z-index:1;
        left: 15px;

    }
    >span{
        display: flex;
        align-items: center;
        color: white;
        padding: 0.3rem;
        border-radius: 50%;
        font-weight: 500;
        justify-content: center;
        position: absolute;
        width: 25px;
        height: 25px;
        top: 15px;
        z-index:1;
        left: 15px;
    }
    img {
        filter: saturate(1.30);
        width: 100%;
        height: 100%;

        object-fit: cover;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
            transform: scale(1.1);
            opacity: 0.9;

            .buttonImg{
                display:flex;
                visibility: visible;
            }
        }
    }


    .LabelsActive {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        gap: 0.3rem;
        flex-wrap: wrap;
        position: absolute;
        bottom: 0.5rem;

        .tagEnable {
            margin-right: 0.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            padding: 0.5rem;
            font-weight: 900;
            font-size: 0.6rem;
            height: 20px;
            border-radius: 0.2rem;
            background-color: #52b303;
        }
    }
    .tagNumber{
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
        background-color:#dc3545;
        font-size:14px;
        color:white;
    }

    .tagDisable {
        display: flex;
        font-size: 0.6rem;
        align-items: center;
        justify-content: center;
        color: white;
        padding: 0.5rem;
        font-weight: 900;
        height: 20px;
        border-radius: 0.2rem;
        background-color: #f85959;
    }
}
.session2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 20%;
    margin-bottom: 1rem;
    font-size: 0.7rem;
    padding: 0 0.5rem;
    p {
        width: 90%;
        margin-top: 0.3rem;
        font-size: 0.7rem;
        color:  rgb(87, 87, 87);
        font-weight: 900;
    }
    ul {
        margin: 0 0;
        li {
            margin:0;
            font-size: 0.5rem;
            color: rgb(173, 172, 172);
        }
    }
}

.session3 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    .contentSession3 {
        width: 100%;
        margin-bottom: -3.5rem;
        gap: 0.5rem;
        display: flex;
        flex-direction: column;
        .contentSelect {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 8px;
           div{
            display: flex;
            justify-content: flex-end;
            width: 90%;
            margin: auto;
            select {
                width: 80%;
                margin: 0.3rem auto;
                font-size: 0.75rem;
                border: none;
                border-radius: 0.3rem;
                border: 1px solid rgb(213, 213, 213);

            }
           }


        }

        .contentCheckbox {

            font-weight: 900;
            color: black;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 0.6rem;
            margin-bottom: 1.9rem;
            >div{
                font-size: 0.6rem;
                margin-bottom: 2.5rem;
                display: flex;
                flex-wrap: wrap;
                align-items: center;


            }

            input{
                border: none;
                border-radius: 0.5rem;
            }

        }
    }
}
.contentSession3 {
    width: 100%;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    .contentSelect {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 8px;
        div{
        display: flex;
        justify-content: flex-end;
        width: 90%;
        margin: auto;
        select {
            width: 80%;
            height: 60%;
            margin: 0.3rem auto;
            font-size: 0.6rem;
            border: none;
            border-radius: 0.3rem;
            border: 1px solid rgb(213, 213, 213);

        }
       }


    }

    .contentCheckbox {
        font-weight: 900;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 0.6rem;
        margin-bottom: 1.9rem;
        >div{
            font-size: 0.6rem;
            height: 1rem;
            margin-bottom: 2rem;
            display: flex;
            gap: 0.3rem;
            align-items: center;


        }

        input{
            border: none;
            border-radius: 0.5rem;
        }

    }
}
.session4 {
    display: flex;
    width: 90%;
    gap: 0.8rem;
    margin: auto;
    .buttonAction {
        cursor: pointer;
        font-size: 0.7rem;
        height: 25px;
        border: none;
        background-color: #52b303;
        color: white;
        font-weight: 900;
        width: 95%;
        border-radius: 0.2rem;
        margin: auto;
        margin-bottom: 0.5rem;
    }
    .buttonAction2 {
        cursor: pointer;
        font-size: 0.7rem;
        height: 25px;
        border: none;
        background-color: #f23c28;
        color: white;
        font-weight: 900;
        width: 95%;
        border-radius: 0.2rem;
        margin: auto;
        margin-bottom: 0.5rem;
    }

    .buttonImg{
        left: 0;
        right: 0;
        top: 35%;
        position: absolute;
        cursor: pointer;
        font-size: 0.7rem;
        height: 30px;
        border: none;
        background-color: #f23c28;
        color: white;
        font-weight: 900;
        width: 45%;
        border-radius: 0.3rem;
        margin: auto;

    }
}

@media(max-width:1720px){
    .card{

         label,li, ul, p, h1, h2,h3,h4, span, select{
            font-size: 0.55rem;
        }
        select{
            height: 15px;
            font-size: 0.6rem;
        }
        input{
            height: 10px;
        }
        .session1{
           .tagDisable{
                font-size: 0.45rem;
                min-width: 28%;
            }
            .tagEnable{
                font-size: 0.45rem;
                min-width: 28%;
            }
        }
        .session2{
            margin-bottom: 0.5rem;
        }

        .session3{
            .contentSelect{
                div{
                    display: flex;
                    align-items: center;
                    select{
                        font-size: 0.6rem;
                        margin: 0.1rem auto;
                    }
                }

            }
            .contentCheckbox{
                gap: 0.3rem;
            }
        }
       .session4{
           .buttonAction, .buttonAction2{
               height: 20px;
           }
       }
    }
}

@media (max-width:720px) {
    .card{
        min-height: 250px;
    }
}