@keyframes is-rotating {
    to {
        transform: rotate(1turn);
    }
}

@keyframes fade-in {
    0%{
        transform:translateX(-50px)
    }
    100%{
        transform:translateX(0);
        opacity: 1;
    }
}

.loader {
    svg {
        animation: is-rotating 1s infinite;
    }
}

.container {
    display: flex;
    align-items: center;
    min-height: 100vh;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -20;
    background-image: url("../../assets/ctwlk-cloud-bg.jpg");
    background-color: #070707;
    background-size: cover;
    color: rgb(245, 245, 245);
}


.container_form {
    background-color: rgba(0, 0, 0, 0.8);
    height: 90vh;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    animation: fade-in 1s 1 forwards;
    flex: 0.7;
    margin-left: 5vh;

    form {
        width: 60%;
        display: flex;
        gap: 0.8rem;
        flex-direction: column;
        margin-bottom: 4rem;
        p {
            color: rgb(240, 48, 48);
        }
        .content_input{
            position: relative;
            svg{
                position: absolute;
                bottom: 30%;
                left: 10px;
            }
            input{
                padding-left: 3.5rem;
            }
        }


        input {
            background-color: transparent;
            border: none;
            font-size: 1rem;
            border-bottom: 1px solid white;
            padding: 1rem;
            width: 100%;
            color: white;
            outline: none;

            &:hover {
                background-color: rgba(0, 0, 0, 0.2);
                box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.3);
                border-bottom: 1px solid #fff2c6;
                transition: 0.4s;
            }

            &:focus {
                background-color: rgba(0, 0, 0, 0.2);
                box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.726);
                outline: none;
            }
        }

        button {
            position: relative;
            letter-spacing: 1px;
            margin-top: 3rem;
            color: #ffffff;
            text-shadow: -1px -1px 0 rgb(0 0 0 /30%);
            border: none;
            border-radius: 0.2rem;
            box-shadow: 0px 2px 2px 0 rgb(0 0 0 /70%);
            background: linear-gradient(#272727, #171717);
            height: 48px;
            padding: 0.8rem;
            z-index: 1;
            cursor: pointer;

            &::before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(#242424, #131313);
                opacity: 0;
                z-index: -1;
                transition: opacity 0.3s ease-in-out;
            }

            &:hover::before {
                opacity: 1;
            }
        }

        > span {
            display: flex;
            margin-left: auto;
            font-size: 0.7rem;
            cursor: pointer;
            &:hover {
                color: #fcc000;
            }
        }
    }
    > span {
        display: flex;
        margin-left: auto;
        font-size: 0.7rem;
        cursor: pointer;
        &:hover {
            color: #fcc000;
        }
    }
}

.formHeader {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    margin-bottom: 3rem;

    h2 {
        color:white;
        font-weight: 700;
        font-size: 1.3rem;
    }

    h3 {
        color:white;
        letter-spacing: 2px;
        font-weight: 400;
        font-size: 1rem;
    }

    p {
        color: rgb(60, 60, 60);
    }
}
.form_logo{
    display: none;
    justify-content: flex-end;
    width: 78%;
    height: 20px;
    img{

        object-fit: contain;
        width: 90px;
        height: 70%;
        
    }

}
.container_logo {
    flex: 1.5;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        letter-spacing: 4px;
        font-size: 50px;
        font-weight: 400;
    }

    div {
        bottom: 50px;
        right: 50px;
        position: absolute;
    }
}

.optionsSign {
    display: flex;
    width: 70%;
    min-width: 250px;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: -4rem;
    align-items: center;
    justify-content: center;
    > span {
        margin: 0 0.5rem;
        cursor: pointer;
        font-size: 0.7rem;
        &:hover {
            color: #fcc000;
        }
    }
}
.backSignin {
    display: flex;
    font-size: 0.7rem;
    margin-left: auto;
    cursor: pointer;
    &:hover {
        color: #fcc000;
    }
}
.labelInform{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    word-break: break-all;
    margin-bottom: 0.5rem;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.24);
    transition: all 0s 50000s;
}

@media (max-width: 1500px) {
    .container_form {
        font-size: 0.8rem;
        form {
            gap: 0.3rem;
            input {
                font-size: 0.9rem;
            }
        }
    }
}

@media (max-width:912px) {
    .container_logo{
        display: none;
    }
    .container_form{
        flex: 1;
        margin: auto;
        height: 100vh;
        form, .formHeader{
            width: 80%;
        
        }
        form{
            height: 50%;
        }
    }
    .form_logo{
        display: flex;
    }
}
