header {
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FAFAFA;
    > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 20px;
        position: absolute;
        width: auto;
        top: 1rem;
        right: 5vw;
        transition: all 0.3s ease;
        overflow: hidden;
        cursor: pointer;

        &:hover {
            height: 80px;
            .logout{
                opacity: 1;
            }
        }
    }
}

.content_logout {
    display: flex;
    align-items: flex-start;
    gap: 1rem;


    h4 {
        font-weight: 700;
        font-size: 1rem;
    }
    img {
        z-index: 15;
        border-radius: 0.2rem;
        width: 20px;
    } 
}
.logout {
    display: flex;
    gap: 0.4rem;
    font-size: 0.7rem;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    max-width: 100px;
    margin-top: 0.2rem;
    display: flex;
    border: none;
    background-color: white;
    border-radius: 0.2rem;
    border: 1px solid rgb(191, 191, 191);
    margin-left: auto;
    opacity: 0;
    transition: all 0.4s ease;
    padding: 0.8rem;
svg{
   transform: scale(1.2);
}
    &:hover{
        cursor: pointer;
        background-color: rgb(248, 89, 89);
        color: white;
        font-weight: 700;
    }

}
@media (max-width:1444px) {

    h4{
        top: 13px;
        font-size: 1rem,
    }
}

@media (max-width:920px){
    header{
        display: none;
    }
}