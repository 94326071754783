.searchBar {
    border: 1px solid rgb(214, 214, 214);
    width: 80%;
    height: 100%;
    height: 2rem;

    padding-left: 1rem;
    z-index: 1;
}
.contentSearchBar{
    position: relative;
    margin-right: 4.4vw;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    width: 40%;
    height: 32px;
    span{
        display: flex;
        align-items: center;
        border-radius: 0.3rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        height: 100%;
        background-color: #F8F8F8;
        border: 1px solid rgb(214, 214, 214);
        border-right: 0;
        svg{
            fill:rgb(32, 32, 32);
        }
    }
    button{
        border: none;
        border: 1px solid rgb(214, 214, 214);
        border-left: none;
        background-color: #F8F8F8;
        border-radius: 0.3rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 50px;
        height: 100%;
        color: rgb(85, 85, 85);
        cursor: pointer;

        transition: all ease 0.2s;
        &:hover{
            opacity: 0.87;
        }
    }
}

.searchBar:focus {

    outline: 1px solid #e6e6e6;
    box-shadow: inset 0px 0px 4px #b1b1b1;
    box-shadow: 0px 0px 6px #838383;

}

