@keyframes Show {
    100% {
        opacity: 1;
    }
}

.container {
    z-index: 4;
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    font-size: 0.75rem;
    background-color: rgba(0, 0, 0, 0.823);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .contentError {
        overflow: hidden;
        border-radius: 0.4rem;
        display: flex;
        align-items:center;
        justify-content: center;
        position: relative;
        width: 1.68 * 32vh;
        height: 32vh;
        background-color: rgb(255, 255, 255);
        opacity: 0;
        animation: Show 1 0.5s forwards;
        text-align: center;
        color: #313131;
        .contentImg{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                margin: auto;
                fill: #ffff;
                background-color: #b50000;
                border-radius: 50%;
                border: 1px solid white;
            }
        }


        div{
            display: flex;
            margin: auto;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            width: 80%;
            height: 100%;
            h3{
                color: #b50000;
                font-weight: 900;
                margin-top: 2rem;
                margin-bottom: 1.3rem;
                font-size: 1.2rem;
            }
            p {
                margin-bottom: auto;
                font-size: 0.9rem;
                text-align: justify;
                width: 80%;
                font-weight: 900;
             }
             button {
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 margin: 0 10%;
                 background-color:#b50000;
                 color: white;
                 border: 2px solid white;
                 border-radius: 0.4rem;
                 margin-bottom: 1rem;
                 border: none;
                 width: 200px;
                 height: 35px;
                 font-weight: 900;
                 transition: background 0.2s ease;
                 cursor: pointer;
                 &:hover {
                     border:2px solid #b50000;
                     background-color: white;
                     color: #b50000;
                 }
             }
        }
    }

}

@media (max-width:1720px) {
    .container{
        .contentError{
            div{
                button{
                    margin: 0 6%;
                    margin-bottom: 1rem;
                }
            }

        }
    }

}
