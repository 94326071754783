@keyframes expandNavigation {
    0% {
        width: 52px;
    }

    100% {
        width: 21vw;
    }
}

@keyframes colapseNavigation {
    0% {
        width: 21vw;
    }

    100% {
        width: 52px;
    }
}

.expandNavigation {
    width: 17vw;
}

.colapseNavigation {
    width: 3rem;
}

.none {
    display: none;
    overflow: hidden;
}

.navigation {
    display: flex;
    z-index: 6;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    flex-direction: column;
    background-color: #3d3d3d;
    min-width: 52px;
    height: 100%;
    align-items: flex-start;
    transition: all 0.4s;
    overflow: hidden;

}

.contentDrop {
    width: 90%;
    font-size: 0.75rem;
    font-weight: 900;
    color: white;
    text-decoration: none;
    transition: all 0.4s ease;


    cursor: pointer;

    div:first-child {
        align-items: center;
        display: flex;
        padding: 0.3rem 0.4rem;
        display: flex;
        gap: 1rem;
        width: 100%;
    }

    &:hover {
        color: #ffc000;
    }

    &:last-child {
        div {
            svg {
                fill: rgb(164, 164, 255);
            }
        }
    }

    .dropdownLinks {
        display: flex;
        margin-top: 0.5rem;
        flex-direction: column;
        margin-left: 2.8rem;

        div {
            border-left: 3px solid rgb(112, 112, 112);
            padding: 0.4rem 1rem;

            &:hover {
                border-left: 3px solid rgb(177, 177, 177);
            }
        }

        .activeDropLink {
            border-left: 3px solid #ffc000;
        }
    }
}

.contentLinks {
    position: absolute;
    display: flex;
    width: 100%;
    min-width: 200px;
    height: 100%;
    background-color: #3d3d3d;
    padding-top: 0.8rem;
    margin-left: 0.6rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;

    .linkHome {
        display: flex;
        margin-left: 0.8rem;
        margin-left: 2px;
        cursor: pointer;

        a {
            margin-left: 6px;
            letter-spacing: 2px;
            font-size: 1rem;

            p {
                display: flex;
                gap: 0.5rem;

                span {
                    font-weight: 400;
                }
            }
        }

        img {
            width: 28px;
            filter: invert(100%);

            &:hover {
                filter: saturate(7442%) hue-rotate(90deg) brightness(106%) contrast(100%);
            }
        }
    }

    a {
        width: 90%;
        font-size: 0.75rem;
        font-weight: 900;
        color: white;
        text-decoration: none;

        div {
            padding: 0.3rem 0.4rem;
            min-width: 164px;
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        &:hover {
            color: #ffc000;
        }

        &:last-child {

            div {
                svg {
                    fill: rgb(164, 164, 255);
                }
            }
        }
    }

    a:nth-child(3) {
        div {
            svg {
                border-radius: 5rem;
            }

        }
    }

    .activeLink {
        color: #ffc000;
        border-radius: 0.4rem;
        background-color: #151414;
    }
}

.menuHamburger{
    display: none;
    P{
        font-weight: 700;
        font-size: 1rem;
        color: #3D3D3D;
        margin: auto;
    }
}
@media (max-width:920px){
    .navigation{
        transform: translateX(-55px);
    }
    .expandNavigation {
        width: 63vw;
        max-width: 450px;
        transform: translateX(0px);
    }
    .menuHamburger{
        display: flex;
        align-items: center;
        background-color: #FAFAFA;
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        height: 40px;
        position: fixed;
        z-index: 2;
        left: 0;
        top: 0;
        svg{
            right: 1rem;
            position: absolute;
            margin-left: 1rem;
            color:white;
            
        }
    }
}